import React, { useRef } from 'react'
import Lottie from "lottie-react"
import lottieJson from '../assets/lotties/75406-looped-404-error-animation.json'

const style = {
  //height: 300,
  width: '100%',
};

const boxStyle = { width: 400, maxWidth: '100%', margin: '0 auto', padding: 5, borderRadius: 7, display: 'flex', flexDirection: 'column' };

const Example = () => {
	const lottieRef = useRef();
  return (
  <div style={boxStyle}>
    <Lottie
      animationData={lottieJson}
      style={style}
    interactivity={false}
    loop={true}
    lottieRef={lottieRef}
    />
	</div>
  );
};

export default Example;
