import React from 'react'
import Layout from '../components/layout'
import Shapes from '../components/Shapes'
import Lottie404 from '../components/lottie404'

const NotFoundPage = () => (
  <Layout>
  <div id="main" className="wrapper style1 ">
  <Shapes />
    <div className="container">
      <header className="major">
      {/*<section style={{ textAlign: 'center'}} id="one">
        <div className="inner">*/}
          <h1>Δυστυχώς η σελίδα δεν βρέθηκε</h1>
      </header>
        {/*  <p>You just hit a route that doesn&#39;t exist... the sadness.</p>*/}
        <div className="row gtr-150">
          <div className="col-12">
            <section id="content">
              <Lottie404 />
            </section>
          </div>
        </div>
    </div>
    </div>
  </Layout>
)

export default NotFoundPage
